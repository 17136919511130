@import "~stylesheets/utilities/variables";
@import "~stylesheets/utilities/colors";

body {
  padding: 0;
  margin: 0;
  background-color: $color-white;
  font-size: $main-font-size;
}

* {
  font-size: inherit;
  overflow: visible;
  box-sizing: border-box;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}

p,
p * {
  font-size: $main-font-size;
  white-space: pre-wrap;
  margin: 0;
}

a {
  color: $color-action-primary-text;
  font-size: inherit;
  text-decoration: none;
}

b,
strong {
  font-weight: $medium-font-weight;
  font-size: inherit;
}

svg {
  fill: currentColor;
}
