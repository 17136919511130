@import "~stylesheets/utilities/colors.scss";
@import "~stylesheets/utilities/variables.scss";

.SettingsSchedulesModal {
  &__input {
    &__error-message {
      margin-top: 2px;
      line-height: 12px;
      font-size: $xx-small-font-size;
      color: $color-ui-warn;
      font-weight: $medium-font-weight;
    }
  }

  &__content {
    padding: 0 !important;
  }

  &__section {
    padding: $g-unit-double;
  }

  &__name-timezone-section {
    display: flex;
  }

  &__name-input {
    flex: 1;
    padding-right: $g-unit-double;
    font-size: $small-font-size;

    &__label {
      margin-bottom: $g-unit-half;
    }
  }

  &__timezone-selector {
    width: 50%;
  }

  &__button-cancel {
    position: absolute;
    left: $g-unit-double;
  }

  &__repeat-every-section,
  &__exception-section {
    border-top: $g-unit $color-grey-1 solid;
  }

  &__panel {
    background: $color-white;
    box-sizing: border-box;
    font-size: $small-font-size;
  }

  &__panel-header {
    padding: 0 0 $g-unit-half 0;
    display: flex;
    align-items: center;
    line-height: 24px;
  }

  &__panel-title {
    font-weight: $medium-font-weight;
    color: $color-black;

    &--greyed {
      color: $color-grey-3-active;
    }
  }

  &__panel-footer {
    &__button {
      width: 100%;
      border: none;
      border-radius: $small-border-radius;
      cursor: pointer;
      background-color: $color-white;
      outline: inherit;
      display: flex;
      color: $color-grey-4;
      padding: $g-unit $g-unit-half;
      margin: $g-unit-half 0;

      &:hover {
        background-color: $color-grey-1;
      }
    }
  }
  &__panel-icon {
    margin-right: $g-unit-half;
    fill: $color-black;
  }

  &__panel-header-button {
    margin-left: auto;
    height: 24px;
  }

  &__panel-divider {
    width: 100%;
    background: $color-grey-2;
    height: 1px;
    border-width: 0px;
    margin: 0;
  }

  &__errors {
    color: $color-ui-warn;
    margin: 0px 20px;
    display: flex;
    align-items: center;

    &__description {
      font-size: $x-small-font-size;
    }
  }

  &__invalid {
    border: 2px solid;
    border-radius: 5px;
    border-color: $color-ui-warn;
  }
}
